import { Col, Layout, Row } from "antd";
import { usePageTitle } from "hooks";
import { IFilterOptions } from "models/interfaces";
import React, { useEffect, useState } from "react";
import ReportLayout from "views/Insights/Reports/ReportLayout";
import DeliveredTicketsTable from "./DeliveredTicketsTable";
import FilterDrawer from "utils/commonFilter/FilterDrawer";
import { useRbac } from "features/rbac/rbac";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ERbacPermissions } from "features/rbac/rbacPermissionsList";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { getOffsetDate } from "utils/formatTimePeriod";
import { actions, dateRange } from "models/Slices/FilterSlice";

const DeliveredTicketsPage: React.FC = () => {
  usePageTitle("Delivered Tickets");
  const [search, setSearch] = useState<string | null>(null);
  const dates = useAppSelector(dateRange);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [filterObject, setFilterObject] = useState<IFilterOptions | null>(null);
  const [outerLoader, setOuterLoader] = useState(false);
  const { hasPermissions } = useRbac();
  const dispatch = useAppDispatch();
  const [filtersAppliedWithValues, setFilterAppliedWithValues] = useState<any>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<any>(null);

  useEffect(() => {
    setOuterLoader(true);
    dispatch(actions.getDateRangeFilterData());
    if (hasPermissions([ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL])) {
      dispatch(actions.getBrokerUserData());
    }
    if (
      hasPermissions([
        ERbacPermissions.FILTER_BUYER_USER_SELECTION_ALL,
        ERbacPermissions.FILTER_BUYER_USER_SELECTION_TEAM,
      ])
    ) {
      dispatch(actions.getBuyerUserData());
    }
    if (hasPermissions([ERbacPermissions.FILTER_SHIPPER_USER_SELECTION_ALL])) {
      dispatch(actions.getShipperUserData());
    }
  }, [dispatch]);

  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearchQuery(search);
  };

  const closeSearch = () => {
    setSearch(null);
    setSearchQuery(null);
  };

  const filterChanged = (filters: any) => {
    setFilterObject(filters);
  };

  useEffect(()=>{
    if(dates.length > 0) {
      setDefaultDateRange({
        preFilledValues: {
          event_date_from: dates[0].event_date_from,
          event_date_to: dates[0].event_date_to,
          invoice_date_from: dates[0].invoice_date_from,
          invoice_date_to: dates[0].invoice_date_to,
          delivery_date_from: getOffsetDate(OffsetMode.SUBTRACT, 29, OffsetType.DAYS),
          delivery_date_to: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS)
        },
        defaultValues: {
          event_date: {
            defaultFrom: dates[0].event_date_from,
            defaultTo: dates[0].event_date_to,
          },
          invoice_date: {
            defaultFrom: dates[0].invoice_date_from,
            defaultTo: dates[0].invoice_date_to
          },
          delivery_date: {
            defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 29, OffsetType.DAYS),
            defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS)
          }
        },
      })
    }
  },[dates]);

  return (
    <React.Fragment>
      <div className="mainHeading">
        <Row className="flx-1">
          <Col xs={24} xl={9} xxl={9} className="gpReportTitle">
            <h2>{"Delivered Tickets Report"}</h2>
          </Col>
          <Col xs={16} xl={8} xxl={7}>
            <div className="search">
              <form onSubmit={handleSearch}>
                <input
                  type="search"
                  placeholder="Search by PO No, Invoice Id, Event Name"
                  value={search ? search : ""}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search && search.length > 0 && (
                  <span onClick={closeSearch}>&times;</span>
                )}
              </form>
            </div>
          </Col>
          <Col xs={8} xl={7} xxl={8}>
          <div className="rightFilter">
            {dates.length > 0 && defaultDateRange &&
              <FilterDrawer
                onApplyFilter={filterChanged}
                onApplyFilterWithValues={(e: any) => setFilterAppliedWithValues(e)}
                showBrokerUserDropdown={hasPermissions([
                  ERbacPermissions.FILTER_BROKER_USER_SELECTION_ALL,
                ])}
                brokerUserDropdownType="single"
                showBuyerUserDropdown={hasPermissions([
                  ERbacPermissions.FILTER_BUYER_USER_SELECTION_ALL,
                  ERbacPermissions.FILTER_BUYER_USER_SELECTION_TEAM,
                ])}
                buyerUserDropdownType="single"
                showShipperUserDropdown={hasPermissions([
                  ERbacPermissions.FILTER_SHIPPER_USER_SELECTION_ALL,
                ])}
                shipperUserDropdownType="single"
                showInvoiceDatePicker={true}
                showEventDatePicker={true}
                showDeliveryDatePicker={true}
                defaultAndValues={defaultDateRange}
                showMissingInventoryTagCheckbox={hasPermissions([
                  ERbacPermissions.FILTER_SHOW_MISSING_INVENTORY_TAG
                ])}
              />
            }
          </div>
          </Col>
        </Row>
      </div>
      <ReportLayout>
        <Layout className="mainInnerLayout pb-0 reduceTopSpace">
          <DeliveredTicketsTable
            searchQuery={searchQuery}
            appliedFilterValues={filtersAppliedWithValues}
            filterObject={filterObject}
            outerLoader={outerLoader}
            setOuterLoader={setOuterLoader}
          />
        </Layout>
      </ReportLayout>
    </React.Fragment>
  );
};

export default DeliveredTicketsPage;
