import React, { useRef, useState, useCallback, useEffect } from "react";
import { Drawer, Button, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { usePrevious } from "hooks";
import {
  IAddonFilter,
  IDefaultAndValues,
  IDefaultFormValues,
  IFilterOptions,
  IListElement,
} from "models/interfaces";
import {
  actions,
  alerts,
  brokers,
  buyers,
  cards,
  currencies,
  customerClassifications,
  dateRange,
  events,
  recipients,
  reports,
  saleCategories,
  venues,
  states,
  inventoryCancellationTypes,
  seatIntelligenceData,
  scrapingStatusData,
  brokerUsers,
  listerUsers,
  venueDatePresets,
  paymentStatusTypes,
  agingPeriodTypes,
  seatMapCreatorUsers,
  checkerUsers,
  fulfillmentStatusTypes,
  showCategories,
  buyerUsers,
  shippers,
  venueScrapingTypes,
} from "models/Slices/FilterSlice";
import { dateType } from "models/types/Reports/dateType";
import FilterOption from "./FilterOption";
import { getCustomDate, getOffsetDate } from "utils/formatTimePeriod";
import { OffsetMode, OffsetType } from "models/enums/dateTime";
import { AppSetting } from "utils/appSettings";
import {
  toggleFilterDrawer,
  isFilterDrawerVisible,
} from "models/Slices/GlobalSlice";
import { titleCase } from "utils/commonFunction";

const FilterDrawer: React.FC<{
  customDatePickerLabel?: string;
  disabled?: boolean;
  filterName?: string;
  current_report_id?: number;
  onApplyFilter: Function;
  onApplyFilterWithValues?: Function;
  showPODatePicker?: boolean;
  showPLDatePicker?: boolean;
  showEventDatePicker?: boolean;
  showCustomDatePicker?: boolean;
  showInvoiceDatePicker?: boolean;
  showFillDatePicker?: boolean;
  showDeliveryDatePicker?: boolean;
  showOtherCostDatePicker?: boolean;
  showBrokerDropdown?: boolean;
  showBuyerDropdown?: boolean;
  showShipperUserDropdown?: boolean;
  showEventDropdown?: boolean;
  showVenueDropdown?: boolean;
  showCurrencyDropdown?: boolean;
  showCardDropdown?: boolean;
  showAlertDropdown?: boolean;
  showReportDropdown?: boolean;
  showRecipientDropdown?: boolean;
  showSaleCategoryDropdown?: boolean;
  showCustomerClassificationDropdown?: boolean;
  showSeatAvailabilityUpdateDropdown?: boolean;
  showNoTicketCheckbox?: boolean;
  showExcludeZeroValuedInventory ?: boolean;
  showActiveItemsOnlyCheckbox?: boolean;
  showInvoiceMappingId?: boolean;
  showListerDropdown?: boolean;
  showStateDropdown?: boolean;
  showInventoryCancellationTypeDropdown?: boolean;
  showSeatIntelligenceDropdown?: boolean;
  showScrapingStatusDropdown?: boolean;
  showBrokerUserDropdown?: boolean;
  showBuyerUserDropdown?: boolean;
  showNewVenuesDropdown?: boolean;
  showOnlyFavoritesCheckbox?: boolean;
  showPricingIssuesCheckbox?: boolean;
  showTbPlacementCheckbox?: boolean;
  showSeatMapCreatorDropdown?: boolean;
  showCheckerDropdown?: boolean;
  showPaymentStatusDropdown?: boolean;
  showAgingPeriodDropdown?: boolean;
  showFulfillmentStatusDropdown?: boolean;
  showResolvedInvoicesCheckbox?: boolean;
  showShowCategoryDropdown?: boolean;
  showFavoriteVenuesOnlyCheckbox?: boolean;
  showSoldOutEventsCheckbox?: boolean;
  showCancelledEventsCheckbox?: boolean;
  showPendingFillsCheckbox?: boolean;
  showSaleTypeDropdown?: boolean;
  showHighPriorityInvoicesCheckbox?: boolean;
  showIncludeMyBrokerCardsCheckbox?: boolean;
  showFailedScrapedEventsCheckbox?: boolean;
  showHighRiskListingsOnlyCheckbox?: boolean;
  showMissingInventoryTagCheckbox?: boolean;
  showBroadcastedCheckbox?: boolean;
  showLowAvailabilityListingsOnlyCheckbox?: boolean;
  showEventsPendingVerificationCheckbox?: boolean;
  showVenueScrapingTypeDropdown?: boolean;

  brokerDropdownType?: "single" | "multiple";
  buyerDropdownType?: "single" | "multiple";
  shipperUserDropdownType?: "single" | "multiple";
  eventDropdownType?: "single" | "multiple";
  venueDropdownType?: "single" | "multiple";
  currencyDropdownType?: "single" | "multiple";
  cardDropdownType?: "single" | "multiple";
  alertDropdownType?: "single" | "multiple";
  reportDropdownType?: "single" | "multiple";
  recipientDropdownType?: "single" | "multiple";
  listerDropdownType?: "single" | "multiple";
  stateDropdownType?: "single" | "multiple";
  inventoryCancellationTypeDropdownType?: "single" | "multiple"; 
  seatIntelligenceDropdownType?: "single" | "multiple";
  scrapingStatusDropdownType?: "single" | "multiple";
  saleCategoryDropdownType?: "single" | "multiple";
  customerClassificationDropdownType?: "single" | "multiple";
  brokerUserDropdownType?: "single" | "multiple";
  buyerUserDropdownType?: "single" | "multiple";
  newVenuesDropdownType?: "single" | "multiple";
  showSeatAvailabilityUpdateDropdownType?: "single" | "multiple";
  defaultAndValues?: IDefaultAndValues;
  paymentStatusDropdownType?: "single" | "multiple";
  agingPeriodDropdownType?: "single" | "multiple";
  seatMapCreatorDropdownType?: "single" | "multiple";
  checkerDropdownType?: "single" | "multiple";
  fulfillmentStatusDropdownType?: "single" | "multiple";
  showCategoryDropdownType?: "single" | "multiple";
  saleTypeDropdownType?: "single" | "multiple";
  venueScrapingTypeDropdownType?: "single" | "multiple";

  addonFilters?: IAddonFilter[];
  setAddonFilters?: Function;
}> = ({
  customDatePickerLabel,
  disabled,
  filterName,
  current_report_id,
  defaultAndValues,
  showPODatePicker,
  showPLDatePicker,
  showEventDatePicker,
  showCustomDatePicker,
  showInvoiceDatePicker,
  showFillDatePicker,
  showDeliveryDatePicker,
  showOtherCostDatePicker,
  showBrokerDropdown,
  showBuyerDropdown,
  showShipperUserDropdown,
  showEventDropdown,
  showVenueDropdown,
  showCurrencyDropdown,
  showCardDropdown,
  showAlertDropdown,
  showReportDropdown,
  showRecipientDropdown,
  showSaleCategoryDropdown,
  showCustomerClassificationDropdown,
  showSeatAvailabilityUpdateDropdown,
  showNoTicketCheckbox,
  showExcludeZeroValuedInventory,
  showActiveItemsOnlyCheckbox,
  showInvoiceMappingId,
  onApplyFilter,
  onApplyFilterWithValues,
  showListerDropdown,
  showStateDropdown,
  showInventoryCancellationTypeDropdown,
  showSeatIntelligenceDropdown,
  showScrapingStatusDropdown = false,
  showBrokerUserDropdown,
  showBuyerUserDropdown,
  showNewVenuesDropdown,
  showOnlyFavoritesCheckbox,
  showPricingIssuesCheckbox,
  showTbPlacementCheckbox,
  showPendingFillsCheckbox,
  showSeatMapCreatorDropdown,
  showCheckerDropdown,
  showPaymentStatusDropdown = false,
  showAgingPeriodDropdown = false,
  showFulfillmentStatusDropdown = false,
  showResolvedInvoicesCheckbox = false,
  showShowCategoryDropdown = false,
  showFavoriteVenuesOnlyCheckbox = false,
  showSoldOutEventsCheckbox = false,
  showCancelledEventsCheckbox = false,
  showHighPriorityInvoicesCheckbox = false,
  showIncludeMyBrokerCardsCheckbox = false,
  showFailedScrapedEventsCheckbox = false,
  showHighRiskListingsOnlyCheckbox = false,
  showMissingInventoryTagCheckbox = false,
  showBroadcastedCheckbox = false,
  showLowAvailabilityListingsOnlyCheckbox = false,
  showEventsPendingVerificationCheckbox = false,
  showSaleTypeDropdown,
  showVenueScrapingTypeDropdown,
  
  brokerDropdownType,
  buyerDropdownType,
  shipperUserDropdownType,
  eventDropdownType,
  venueDropdownType,
  currencyDropdownType,
  cardDropdownType,
  alertDropdownType,
  reportDropdownType,
  recipientDropdownType,
  listerDropdownType,
  stateDropdownType,
  inventoryCancellationTypeDropdownType,
  seatIntelligenceDropdownType,
  scrapingStatusDropdownType,
  saleCategoryDropdownType,
  customerClassificationDropdownType,
  brokerUserDropdownType,
  buyerUserDropdownType,
  newVenuesDropdownType,
  showSeatAvailabilityUpdateDropdownType,
  paymentStatusDropdownType,
  agingPeriodDropdownType,
  seatMapCreatorDropdownType,
  checkerDropdownType,
  fulfillmentStatusDropdownType,
  showCategoryDropdownType,
  saleTypeDropdownType,
  venueScrapingTypeDropdownType,

  addonFilters,
  setAddonFilters,
}) => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(dateRange);
  const brokerList = useAppSelector(brokers);
  const buyerList = useAppSelector(buyers);
  const eventList = useAppSelector(events);
  const venueList = useAppSelector(venues);
  const currencyList = useAppSelector(currencies);
  const cardList = useAppSelector(cards);
  const alertList = useAppSelector(alerts);
  const reportList = useAppSelector(reports);
  const recipientList = useAppSelector(recipients);
  const saleCategoryList = useAppSelector(saleCategories);
  const customerClassificationList = useAppSelector(customerClassifications);
  const stateList = useAppSelector(states);
  const inventoryCancellationTypeList = useAppSelector(inventoryCancellationTypes);
  const seatIntelligenceList = useAppSelector(seatIntelligenceData);
  const scrapingStatusList = useAppSelector(scrapingStatusData);
  const brokerUserList = useAppSelector(brokerUsers);
  const buyerUserList = useAppSelector(buyerUsers);
  const shipperUserList = useAppSelector(shippers);
  const listerUserList = useAppSelector(listerUsers);
  const venueDatePresetList = useAppSelector(venueDatePresets);
  const paymentStatusList = useAppSelector(paymentStatusTypes);
  const agingPeriodList = useAppSelector(agingPeriodTypes);
  const seatMapCreatorUserList = useAppSelector(seatMapCreatorUsers);
  const checkerUserList = useAppSelector(checkerUsers);
  const fulfillmentStatusList = useAppSelector(fulfillmentStatusTypes);
  const showCategoryList = useAppSelector(showCategories);
  const saleTypeList: IListElement[] = [
    // { value: null, label: "All" },
    { value: 1, label: "Long" },
    { value: 2, label: "Zone" },
  ];
  const venueScrapingTypesList = useAppSelector(venueScrapingTypes);

  const seatAvailabilityUpdateOptions = [
    {
      value: 1,
      label: "Overdue",
    },
    {
      value: 2,
      label: "Today",
    },
    {
      value: 3,
      label: "Upcoming",
    },
  ];

  const isFilterDrawerVisibleRedux = useAppSelector(isFilterDrawerVisible);

  const applyFilterRef = useRef<HTMLInputElement | null>(null);
  const [isDefaultAppliedFilter, setIsDefaultAppliedFilter] = useState(false);
  const [isFilterDrawerActive, setIsFilterDrawerActive] = useState(true);
  const [isFilterDrawerReady, setIsFilterDrawerReady] = useState(false);
  const [isAllMasterDataFetched, setIsAllMasterDataFetched] = useState(false);
  const [isAllDatesGotAndSet, setIsAllDatesGotAndSet] = useState(false);
  const prevDefaultAndValues = usePrevious(defaultAndValues);

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currentAppliedFilters, setCurrentAppliedFilters] =
    useState<IFilterOptions | null>(null);
  const [dateRangesToDisplay, setDateRangeToDisplay] = useState<any | null>(
    null
  );
  const [isShowingRedDot, setIsShowingRedDot] = useState(true);

  const [filterFormFields, setFilterFormFields] = useState<IFilterOptions>({
    broker_Id: null,
    buyer_Id: null,
    lister_user_Id: null,
    seat_map_creator_user_id: null,
    checker_user_id: null,
    event_Id: null,
    venue_Id: null,
    credit_card_Id: null,
    alert_Id: null,
    currency_Id: null,
    report_Id: null,
    recipient_Id: null,
    sale_category: null,
    customer_classification: null,
    show_active_items_only: false,
    no_ticketCost: false,
    state_Id: null,
    inventory_cancellation_type_Id: null,
    seat_intelligence_Id: null,
    scraping_status: null,
    broker_user_Id: null,
    buyer_user_Id: null,
    shipper_user_Id: null,
    seat_availability_update: null,
    payment_status: null,
    aging_period: null,
    fulfillment_status: null,

    pl_date_from: null,
    pl_date_to: null,
    invoice_date_from: null,
    invoice_date_to: null,
    fill_date_from: null,
    fill_date_to: null,
    po_date_from: null,
    po_date_to: null,
    event_date_from: null,
    event_date_to: null,
    custom_date_from: null,
    custom_date_to: null,
    delivery_date_from: null,
    delivery_date_to: null,
    other_cost_date_from: null,
    other_cost_date_to: null,
    show_invoice_mapping_id: null,
    new_venue_Id: null,
    show_only_favorites: false,
    show_pricing_issues: false,
    show_tb_placements: false,
    days_to_event: null,
    except_broker_user_ids: null,
    show_resolved_invoices: false,
    show_category_id: null,
    favorite_venues_only: false,
    exclude_zero_valued_inventory: false,
    exclude_sold_out_events: false,
    exclude_cancelled_events: false,
    show_scrape_failed_events: false,
    high_priority_invoices: false,
    include_my_broker_cards: false,
    show_pending_fills: false,
    sale_type: null,
    low_or_no_availability_listings_only: false,
    events_pending_verification: false,
    high_risk_listings_only: false,
    show_broadcasted_listings: false,
    venue_scraping_type_id: null,
    show_missing_inventory_tag: false,
  });

  const [isResetAddonFilters, setIsResetAddonFilters] = useState(false);

  const [defaultFormValues, setDefaultFormValues] =
    useState<IDefaultFormValues>({
      pl_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
      },
      invoice_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
      },
      fill_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
      },
      po_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getCustomDate(AppSetting.DEFAULT_PO_FROM),
        defaultTo: getCustomDate(),
      },
      event_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 15, OffsetType.DAYS),
        defaultTo: getCustomDate(AppSetting.DEFAULT_EVENT_TO),
      },
      custom_date: {
        rangeFrom: getCustomDate("2021-01-01"),
        rangeTo: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS),
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 29, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS),
      },
      delivery_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 29, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 0, OffsetType.DAYS),
      },
      other_cost_date: {
        rangeFrom: null,
        rangeTo: null,
        defaultFrom: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
        defaultTo: getOffsetDate(OffsetMode.SUBTRACT, 1, OffsetType.DAYS),
      },

      broker_Id: null,
      lister_user_Id: null,
      buyer_user_Id: null,
      shipper_user_Id: null,
      seat_map_creator_user_id: null,
      checker_user_id: null,
      buyer_Id: null,
      event_Id: null,
      venue_Id: null,
      credit_card_Id: null,
      alert_Id: null,
      currency_Id: 1,
      report_Id: null,
      recipient_Id: null,
      state_Id: null,
      inventory_cancellation_type_Id: null,
      seat_intelligence_Id: null,
      scraping_status: null,
      sale_category: null,
      customer_classification: null,
      no_ticketCost: false,
      show_active_items_only: false,
      show_invoice_mapping_id: null,
      broker_user_Id: null,
      new_venue_Id: null,
      seat_availability_update: null,
      show_only_favorites: false,
      show_pricing_issues: false,
      show_tb_placements: false,
      new_shows_no_seat_structure: false,
      new_shows_no_listing: false,
      new_shows_with_seat_structure: false,
      all_other_shows_no_seat_structure: false,
      shows_with_active_listing: false,
      shows_without_listings: false,
      shows_without_seat_structure: false,
      shows_without_availability_and_cost: false,
      payment_status: null,
      aging_period: null,
      days_to_event: null,
      except_broker_user_ids: null,
      fulfillment_status: null,
      show_resolved_invoices: false,
      show_category_id: null,
      favorite_venues_only: false,
      exclude_zero_valued_inventory: false,
      exclude_sold_out_events: false,
      exclude_cancelled_events: false,
      show_scrape_failed_events: false,
      high_priority_invoices: false,
      include_my_broker_cards: false,
      show_pending_fills: false,
      sale_type: null,
      low_or_no_availability_listings_only: false,
      events_pending_verification: false,
      high_risk_listings_only: false,
      show_broadcasted_listings: false,
      venue_scraping_type_id: null,
      show_missing_inventory_tag: false,
    });

  let modAddonFilters: any[] = [];
  if (addonFilters) {
    modAddonFilters = addonFilters.map((eachAddon) => ({
      field_id: eachAddon.filter_key,
      field_name: eachAddon.filter_label,
      field_value: titleCase(eachAddon.filter_label_value),
    }));
  }

  const setDefaultAndValues = useCallback(() => {
    if (defaultAndValues?.defaultValues) {
      if (defaultAndValues?.defaultValues?.pl_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          pl_date: {
            ...prevState.pl_date,
            defaultFrom: defaultAndValues?.defaultValues?.pl_date?.defaultFrom,
            defaultTo: defaultAndValues?.defaultValues?.pl_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.invoice_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          invoice_date: {
            ...prevState.invoice_date,
            defaultFrom:
              defaultAndValues.defaultValues?.invoice_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.invoice_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.fill_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          fill_date: {
            ...prevState.fill_date,
            defaultFrom: defaultAndValues.defaultValues?.fill_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.fill_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.po_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          po_date: {
            ...prevState.po_date,
            defaultFrom: defaultAndValues.defaultValues?.po_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.po_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.event_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          event_date: {
            ...prevState.event_date,
            defaultFrom:
              defaultAndValues.defaultValues?.event_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.event_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.delivery_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          delivery_date: {
            ...prevState.delivery_date,
            defaultFrom:
              defaultAndValues.defaultValues?.delivery_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.delivery_date?.defaultTo,
          },
        }));
      }

      if (defaultAndValues?.defaultValues?.other_cost_date) {
        setDefaultFormValues((prevState) => ({
          ...prevState,
          other_cost_date: {
            ...prevState.other_cost_date,
            defaultFrom:
              defaultAndValues.defaultValues?.other_cost_date?.defaultFrom,
            defaultTo: defaultAndValues.defaultValues?.other_cost_date?.defaultTo,
          },
        }));
      }
    }
  }, [prevDefaultAndValues, defaultAndValues, setDefaultFormValues]);

  useEffect(() => {
    if (defaultAndValues) {
      setDefaultAndValues();
    }
  }, [defaultAndValues, setDefaultAndValues]);

  useEffect(() => {
    let selectFiltersWithValues: any = [];
    if (currentAppliedFilters && dateRangesToDisplay) {
      const modifiedFilters = {
        ...dateRangesToDisplay,
        ...currentAppliedFilters,
      };

      selectFiltersWithValues = Object.keys(modifiedFilters)
        .filter((key) => modifiedFilters[key] !== null)
        .map((key) => {
          return {
            field_id: key,
            field_name: getAppliedFilterNames(key),
            field_value: key.includes("_date")
              ? modifiedFilters[key]
              : getAppliedFilterValues(key, modifiedFilters[key]),
          };
        });

      if (onApplyFilterWithValues) {
        onApplyFilterWithValues([
          ...selectFiltersWithValues,
          ...modAddonFilters,
        ]);
      }
    }
  }, [
    currentAppliedFilters,
    currencyList,
    brokerList,
    buyerList,
    shipperUserList,
    alertList,
    venueList,
    eventList,
    venueList,
    cardList,
    reportList,
    recipientList,
    saleCategoryList,
    customerClassificationList,
    stateList,
    seatIntelligenceList,
    scrapingStatusList,
    brokerUserList,
    listerUserList,
    seatMapCreatorUserList,
    checkerUserList,
    venueDatePresetList,
    paymentStatusList,
    agingPeriodList,
    fulfillmentStatusList,
    showCategoryList,
    addonFilters,
  ]);

  useEffect(() => {
    if (!isFilterDrawerReady) {
      setFilterFormFields((prevState: any) => ({
        ...prevState,
        pl_date_from: defaultAndValues?.preFilledValues?.pl_date_from
          ? defaultAndValues?.preFilledValues?.pl_date_from
          : defaultFormValues.pl_date.defaultFrom,
        pl_date_to: defaultAndValues?.preFilledValues?.pl_date_to
          ? defaultAndValues?.preFilledValues?.pl_date_to
          : defaultFormValues.pl_date.defaultTo,
        invoice_date_from: defaultAndValues?.preFilledValues?.invoice_date_from
          ? defaultAndValues?.preFilledValues?.invoice_date_from
          : defaultFormValues.invoice_date.defaultFrom,
        invoice_date_to: defaultAndValues?.preFilledValues?.invoice_date_to
          ? defaultAndValues?.preFilledValues?.invoice_date_to
          : defaultFormValues.invoice_date.defaultTo,
        fill_date_from: defaultAndValues?.preFilledValues?.fill_date_from
          ? defaultAndValues?.preFilledValues?.fill_date_from
          : defaultFormValues.fill_date.defaultFrom,
        fill_date_to: defaultAndValues?.preFilledValues?.fill_date_to
          ? defaultAndValues?.preFilledValues?.fill_date_to
          : defaultFormValues.fill_date.defaultTo,
        po_date_from: defaultAndValues?.preFilledValues?.po_date_from
          ? defaultAndValues?.preFilledValues?.po_date_from
          : defaultFormValues.po_date.defaultFrom,
        po_date_to: defaultAndValues?.preFilledValues?.po_date_to
          ? defaultAndValues?.preFilledValues?.po_date_to
          : defaultFormValues.po_date.defaultTo,
        event_date_from: defaultAndValues?.preFilledValues?.event_date_from
          ? defaultAndValues?.preFilledValues?.event_date_from
          : defaultFormValues.event_date.defaultFrom,
        event_date_to: defaultAndValues?.preFilledValues?.event_date_to
          ? defaultAndValues?.preFilledValues?.event_date_to
          : defaultFormValues.event_date.defaultTo,
        custom_date_from: defaultAndValues?.preFilledValues?.custom_date_from
          ? defaultAndValues?.preFilledValues?.custom_date_from
          : defaultFormValues.custom_date.defaultFrom,
        custom_date_to: defaultAndValues?.preFilledValues?.custom_date_to
          ? defaultAndValues?.preFilledValues?.custom_date_to
          : defaultFormValues.custom_date.defaultTo,
        delivery_date_from: defaultAndValues?.preFilledValues?.delivery_date_from
          ? defaultAndValues?.preFilledValues?.delivery_date_from
          : defaultFormValues.delivery_date.defaultFrom,
        delivery_date_to: defaultAndValues?.preFilledValues?.delivery_date_to
          ? defaultAndValues?.preFilledValues?.delivery_date_to
          : defaultFormValues.delivery_date.defaultTo,
        other_cost_date_from: defaultAndValues?.preFilledValues?.other_cost_date_from
          ? defaultAndValues?.preFilledValues?.other_cost_date_from
          : defaultFormValues.other_cost_date.defaultFrom,
        other_cost_date_to: defaultAndValues?.preFilledValues?.other_cost_date_to
          ? defaultAndValues?.preFilledValues?.other_cost_date_to
          : defaultFormValues.other_cost_date.defaultTo,

        broker_Id: defaultAndValues?.preFilledValues?.broker_Id
          ? defaultAndValues?.preFilledValues?.broker_Id
          : defaultFormValues.broker_Id,
        broker_user_Id: defaultAndValues?.preFilledValues?.broker_user_Id
          ? defaultAndValues?.preFilledValues?.broker_user_Id
          : defaultFormValues.broker_user_Id,
        buyer_user_Id: defaultAndValues?.preFilledValues?.buyer_user_Id
          ? defaultAndValues?.preFilledValues?.buyer_user_Id
          : defaultFormValues.buyer_user_Id,
        shipper_user_Id: defaultAndValues?.preFilledValues?.shipper_user_Id
          ? defaultAndValues?.preFilledValues?.shipper_user_Id
          : defaultFormValues.shipper_user_Id,
        lister_user_Id: defaultAndValues?.preFilledValues?.lister_user_Id
          ? defaultAndValues?.preFilledValues?.lister_user_Id
          : defaultFormValues.lister_user_Id,
        seat_map_creator_user_id: defaultAndValues?.preFilledValues
          ?.seat_map_creator_user_id
          ? defaultAndValues?.preFilledValues?.seat_map_creator_user_id
          : defaultFormValues.seat_map_creator_user_id,
        checker_user_id: defaultAndValues?.preFilledValues?.checker_user_id
          ? defaultAndValues?.preFilledValues?.checker_user_id
          : defaultFormValues.checker_user_id,
        buyer_Id: defaultAndValues?.preFilledValues?.buyer_Id
          ? defaultAndValues?.preFilledValues?.buyer_Id
          : defaultFormValues.buyer_Id,
        event_Id: defaultAndValues?.preFilledValues?.event_Id
          ? defaultAndValues?.preFilledValues?.event_Id
          : defaultFormValues.event_Id,
        venue_Id: defaultAndValues?.preFilledValues?.venue_Id
          ? defaultAndValues?.preFilledValues?.venue_Id
          : defaultFormValues.venue_Id,
        credit_card_Id: defaultAndValues?.preFilledValues?.credit_card_Id
          ? defaultAndValues?.preFilledValues?.credit_card_Id
          : defaultFormValues.credit_card_Id,
        alert_Id: defaultAndValues?.preFilledValues?.alert_Id
          ? defaultAndValues?.preFilledValues?.alert_Id
          : defaultFormValues.alert_Id,
        currency_Id: defaultAndValues?.preFilledValues?.currency_Id
          ? defaultAndValues?.preFilledValues?.currency_Id
          : defaultFormValues.currency_Id,
        report_Id: defaultAndValues?.preFilledValues?.report_Id
          ? defaultAndValues?.preFilledValues?.report_Id
          : defaultFormValues.report_Id,
        recipient_Id: defaultAndValues?.preFilledValues?.recipient_Id
          ? defaultAndValues?.preFilledValues?.recipient_Id
          : defaultFormValues.recipient_Id,
        seat_availability_update: defaultAndValues?.preFilledValues
          ?.seat_availability_update
          ? defaultAndValues?.preFilledValues?.seat_availability_update
          : defaultFormValues.seat_availability_update,
        payment_status: defaultAndValues?.preFilledValues?.payment_status
          ? defaultAndValues?.preFilledValues?.payment_status
          : defaultFormValues.payment_status,
        aging_period: defaultAndValues?.preFilledValues?.aging_period
          ? defaultAndValues?.preFilledValues?.aging_period
          : defaultFormValues.aging_period,
        fulfillment_status: defaultAndValues?.preFilledValues
          ?.fulfillment_status
          ? defaultAndValues?.preFilledValues?.fulfillment_status
          : defaultFormValues.fulfillment_status,
        show_category_id: defaultAndValues?.preFilledValues?.show_category_id
          ? defaultAndValues?.preFilledValues?.show_category_id
          : defaultFormValues.show_category_id,
        venue_scraping_type_id: defaultAndValues?.preFilledValues?.venue_scraping_type_id
          ? defaultAndValues?.preFilledValues?.venue_scraping_type_id
          : defaultFormValues.venue_scraping_type_id,
        seat_intelligence_Id: defaultAndValues?.preFilledValues?.seat_intelligence_Id
          ? defaultAndValues?.preFilledValues?.seat_intelligence_Id
          : defaultFormValues.seat_intelligence_Id,

        no_ticketCost: false,
        show_active_items_only: false,
        show_invoice_mapping_id: defaultAndValues?.preFilledValues
          ?.show_invoice_mapping_id
          ? defaultAndValues?.preFilledValues?.show_invoice_mapping_id
          : defaultFormValues.show_invoice_mapping_id,
        state_Id: defaultAndValues?.preFilledValues?.state_Id
          ? defaultAndValues?.preFilledValues?.state_Id
          : defaultFormValues.state_Id,
        inventory_cancellation_type_Id: defaultAndValues?.preFilledValues?.inventory_cancellation_type_Id
          ? defaultAndValues?.preFilledValues?.inventory_cancellation_type_Id
          : defaultFormValues.inventory_cancellation_type_Id,
        show_only_favorites: false,
        show_pricing_issues: false,
        show_tb_placements: false,
        days_to_event: null,
        except_broker_user_ids: null,
        show_resolved_invoices: false,
        favorite_venues_only: false,
        exclude_zero_valued_inventory: defaultAndValues?.preFilledValues?.exclude_zero_valued_inventory
        ? defaultAndValues?.preFilledValues?.exclude_zero_valued_inventory
        : defaultFormValues.exclude_zero_valued_inventory,
        exclude_sold_out_events: defaultAndValues?.preFilledValues?.exclude_sold_out_events
        ? defaultAndValues?.preFilledValues?.exclude_sold_out_events
        : defaultFormValues.exclude_sold_out_events,
        exclude_cancelled_events: defaultAndValues?.preFilledValues?.exclude_cancelled_events
        ? defaultAndValues?.preFilledValues?.exclude_cancelled_events
        : defaultFormValues.exclude_cancelled_events,
        high_priority_invoices: false,
        include_my_broker_cards: false,
        show_scrape_failed_events: defaultAndValues?.preFilledValues?.show_scrape_failed_events
        ? defaultAndValues?.preFilledValues?.show_scrape_failed_events
        : defaultFormValues.show_scrape_failed_events,
        show_pending_fills: false,
        low_or_no_availability_listings_only: false,
        events_pending_verification: false,
        high_risk_listings_only: false,
        show_broadcasted_listings: defaultAndValues?.preFilledValues?.show_broadcasted_listings
        ? defaultAndValues?.preFilledValues?.show_broadcasted_listings
        : defaultFormValues.show_broadcasted_listings,
      }));
      setIsFilterDrawerReady(true);
    }
  }, [defaultFormValues, defaultAndValues, isFilterDrawerReady]);

  const getSelectedDropdownValues = (values: any) => {
    if (values !== undefined && values !== "") {
      return Array.isArray(values) ? values.join(",") : values;
    }
    return null;
  };

  const doApplyFilter = () => {
    const selectedFilters: any = {};
    const appliedDateRange: any = {};

    if (showPODatePicker) {
      selectedFilters.po_date_from = filterFormFields.po_date_from;
      selectedFilters.po_date_to = filterFormFields.po_date_to;
      appliedDateRange.po_date_range = `${filterFormFields.po_date_from} - ${filterFormFields.po_date_to}`;
    }
    if (showPLDatePicker) {
      selectedFilters.pl_date_from = filterFormFields.pl_date_from;
      selectedFilters.pl_date_to = filterFormFields.pl_date_to;
      appliedDateRange.pl_date_range = `${filterFormFields.pl_date_from} - ${filterFormFields.pl_date_to}`;
    }
    if (showEventDatePicker) {
      selectedFilters.event_date_from = filterFormFields.event_date_from;
      selectedFilters.event_date_to = filterFormFields.event_date_to;
      appliedDateRange.event_date_range = `${filterFormFields.event_date_from} - ${filterFormFields.event_date_to}`;
    }
    if (showInvoiceDatePicker) {
      selectedFilters.invoice_date_from = filterFormFields.invoice_date_from;
      selectedFilters.invoice_date_to = filterFormFields.invoice_date_to;
      appliedDateRange.invoice_date_range = `${filterFormFields.invoice_date_from} - ${filterFormFields.invoice_date_to}`;
    }
    if (showFillDatePicker) {
      selectedFilters.fill_date_from = filterFormFields.fill_date_from;
      selectedFilters.fill_date_to = filterFormFields.fill_date_to;
      appliedDateRange.fill_date_range = `${filterFormFields.fill_date_from} - ${filterFormFields.fill_date_to}`;
    }
    if (showCustomDatePicker) {
      selectedFilters.custom_date_from = filterFormFields.custom_date_from;
      selectedFilters.custom_date_to = filterFormFields.custom_date_to;
      appliedDateRange.custom_date_range = `${filterFormFields.custom_date_from} - ${filterFormFields.custom_date_to}`;
    }
    if (showDeliveryDatePicker) {
      selectedFilters.delivery_date_from = filterFormFields.delivery_date_from;
      selectedFilters.delivery_date_to = filterFormFields.delivery_date_to;
      appliedDateRange.delivery_date_range = `${filterFormFields.delivery_date_from} - ${filterFormFields.delivery_date_to}`;
    }
    if (showOtherCostDatePicker) {
      selectedFilters.other_cost_date_from = filterFormFields.other_cost_date_from;
      selectedFilters.other_cost_date_to = filterFormFields.other_cost_date_to;
      appliedDateRange.other_cost_date_range = `${filterFormFields.other_cost_date_from} - ${filterFormFields.other_cost_date_to}`;
    }

    if (showBuyerDropdown) {
      selectedFilters.buyer_Id = filterFormFields.buyer_Id
        ? getSelectedDropdownValues(filterFormFields.buyer_Id)
        : null;
    }

    if (showShipperUserDropdown) {
      selectedFilters.shipper_user_Id = filterFormFields.shipper_user_Id
        ? getSelectedDropdownValues(filterFormFields.shipper_user_Id)
        : null;
    }

    if (showBrokerDropdown) {
      selectedFilters.broker_Id =
        filterFormFields.broker_Id !== undefined
          ? getSelectedDropdownValues(filterFormFields.broker_Id)
          : null;
    }

    if (showListerDropdown) {
      selectedFilters.lister_user_Id =
        filterFormFields.lister_user_Id !== undefined
          ? getSelectedDropdownValues(filterFormFields.lister_user_Id)
          : null;
    }

    if (showSeatMapCreatorDropdown) {
      selectedFilters.seat_map_creator_user_id =
        filterFormFields.seat_map_creator_user_id !== undefined
          ? getSelectedDropdownValues(filterFormFields.seat_map_creator_user_id)
          : null;
    }

    if (showCheckerDropdown) {
      selectedFilters.checker_user_id =
        filterFormFields.checker_user_id !== undefined
          ? getSelectedDropdownValues(filterFormFields.checker_user_id)
          : null;
    }

    if (showEventDropdown) {
      selectedFilters.event_Id = filterFormFields.event_Id
        ? getSelectedDropdownValues(filterFormFields.event_Id)
        : null;
    }

    if (showVenueDropdown) {
      selectedFilters.venue_Id = filterFormFields.venue_Id
        ? getSelectedDropdownValues(filterFormFields.venue_Id)
        : null;
    }

    if (showNewVenuesDropdown) {
      selectedFilters.new_venue_Id = filterFormFields.new_venue_Id
        ? getSelectedDropdownValues(filterFormFields.new_venue_Id)
        : null;
    }

    if (showCurrencyDropdown) {
      selectedFilters.currency_Id = filterFormFields.currency_Id
        ? getSelectedDropdownValues(filterFormFields.currency_Id)
        : null;
    }

    if (showCardDropdown) {
      selectedFilters.credit_card_Id = filterFormFields.credit_card_Id
        ? getSelectedDropdownValues(filterFormFields.credit_card_Id)
        : null;
    }

    if (showAlertDropdown) {
      selectedFilters.alert_Id = filterFormFields.alert_Id
        ? getSelectedDropdownValues(filterFormFields.alert_Id)
        : null;
    }

    if (showReportDropdown) {
      selectedFilters.report_Id = filterFormFields.report_Id
        ? getSelectedDropdownValues(filterFormFields.report_Id)
        : null;
    }

    if (showRecipientDropdown) {
      selectedFilters.recipient_Id = filterFormFields.recipient_Id
        ? getSelectedDropdownValues(filterFormFields.recipient_Id)
        : null;
    }

    if (showSaleCategoryDropdown) {
      selectedFilters.sale_category = filterFormFields.sale_category
        ? getSelectedDropdownValues(filterFormFields.sale_category)
        : null;
    }

    if (showCustomerClassificationDropdown) {
      selectedFilters.customer_classification =
        filterFormFields.customer_classification
          ? getSelectedDropdownValues(filterFormFields.customer_classification)
          : null;
    }

    if (showStateDropdown) {
      selectedFilters.state_Id = filterFormFields.state_Id
        ? getSelectedDropdownValues(filterFormFields.state_Id)
        : null;
    }

    if(showInventoryCancellationTypeDropdown) {
      selectedFilters.inventory_cancellation_type_Id = filterFormFields.inventory_cancellation_type_Id
        ? getSelectedDropdownValues(filterFormFields.inventory_cancellation_type_Id)
        : null;
    }

    if (showSeatIntelligenceDropdown) {
      selectedFilters.seat_intelligence_Id =
        filterFormFields.seat_intelligence_Id
          ? getSelectedDropdownValues(filterFormFields.seat_intelligence_Id)
          : null;
    }

    if (showScrapingStatusDropdown) {
      selectedFilters.scraping_status = filterFormFields.scraping_status
        ? getSelectedDropdownValues(filterFormFields.scraping_status)
        : null;
    }

    if (showBrokerUserDropdown) {
      selectedFilters.broker_user_Id = filterFormFields.broker_user_Id
        ? getSelectedDropdownValues(filterFormFields.broker_user_Id)
        : null;
    }

    if (showBuyerUserDropdown) {
      selectedFilters.buyer_user_Id = filterFormFields.buyer_user_Id
        ? getSelectedDropdownValues(filterFormFields.buyer_user_Id)
        : null;
    }

    if (showVenueScrapingTypeDropdown) {
      selectedFilters.venue_scraping_type_id = filterFormFields.venue_scraping_type_id
        ? getSelectedDropdownValues(filterFormFields.venue_scraping_type_id)
        : null;
    }

    if (showSeatAvailabilityUpdateDropdown) {
      selectedFilters.seat_availability_update =
        filterFormFields.seat_availability_update
          ? getSelectedDropdownValues(filterFormFields.seat_availability_update)
          : null;
    }

    if (showNoTicketCheckbox) {
      selectedFilters.no_ticketCost = filterFormFields.no_ticketCost
        ? filterFormFields.no_ticketCost
        : false;
    }

    if(showExcludeZeroValuedInventory) {
      selectedFilters.exclude_zero_valued_inventory = filterFormFields.exclude_zero_valued_inventory
        ? filterFormFields.exclude_zero_valued_inventory
        : false;
    }

    if (showFavoriteVenuesOnlyCheckbox) {
      selectedFilters.favorite_venues_only =
        filterFormFields.favorite_venues_only
          ? filterFormFields.favorite_venues_only
          : false;
    }

    if (showSoldOutEventsCheckbox) {
      selectedFilters.exclude_sold_out_events =
        filterFormFields.exclude_sold_out_events
          ? filterFormFields.exclude_sold_out_events
          : false;
    }

    if (showCancelledEventsCheckbox) {
      selectedFilters.exclude_cancelled_events =
        filterFormFields.exclude_cancelled_events
          ? filterFormFields.exclude_cancelled_events
          : false;
    }

    if (showFailedScrapedEventsCheckbox) {
      selectedFilters.show_scrape_failed_events =
        filterFormFields.show_scrape_failed_events
          ? filterFormFields.show_scrape_failed_events
          : false;
    }

    if (showHighPriorityInvoicesCheckbox) {
      selectedFilters.high_priority_invoices =
        filterFormFields.high_priority_invoices
          ? filterFormFields.high_priority_invoices
          : false;
    }

    if (showIncludeMyBrokerCardsCheckbox) {
      selectedFilters.include_my_broker_cards =
        filterFormFields.include_my_broker_cards
          ? filterFormFields.include_my_broker_cards
          : false;
    }

    if (showActiveItemsOnlyCheckbox) {
      selectedFilters.show_active_items_only =
        filterFormFields.show_active_items_only
          ? filterFormFields.show_active_items_only
          : false;
    }

    if (showOnlyFavoritesCheckbox) {
      selectedFilters.show_only_favorites = filterFormFields.show_only_favorites
        ? filterFormFields.show_only_favorites
        : false;
    }

    if (showMissingInventoryTagCheckbox) {
      selectedFilters.show_missing_inventory_tag = filterFormFields.show_missing_inventory_tag
        ? filterFormFields.show_missing_inventory_tag
        : false;
    }

    if (showInvoiceMappingId) {
      selectedFilters.show_invoice_mapping_id =
        filterFormFields.show_invoice_mapping_id
          ? filterFormFields.show_invoice_mapping_id
          : null;
    }

    if (showPricingIssuesCheckbox) {
      selectedFilters.show_pricing_issues = filterFormFields.show_pricing_issues
        ? getSelectedDropdownValues(filterFormFields.show_pricing_issues)
        : null;
    }

    if (showTbPlacementCheckbox) {
      selectedFilters.show_tb_placements = filterFormFields.show_tb_placements
        ? getSelectedDropdownValues(filterFormFields.show_tb_placements)
        : null;
    }

    if (showResolvedInvoicesCheckbox) {
      selectedFilters.show_resolved_invoices =
        filterFormFields.show_resolved_invoices
          ? getSelectedDropdownValues(filterFormFields.show_resolved_invoices)
          : false;
    }

    if (showPaymentStatusDropdown) {
      selectedFilters.payment_status =
        filterFormFields.payment_status !== undefined
          ? getSelectedDropdownValues(filterFormFields.payment_status)
          : null;
    }

    if (showAgingPeriodDropdown) {
      selectedFilters.aging_period =
        filterFormFields.aging_period !== undefined
          ? getSelectedDropdownValues(filterFormFields.aging_period)
          : null;
    }

    if (showFulfillmentStatusDropdown) {
      selectedFilters.fulfillment_status =
        filterFormFields.fulfillment_status !== undefined
          ? getSelectedDropdownValues(filterFormFields.fulfillment_status)
          : null;
    }

    if (showShowCategoryDropdown) {
      selectedFilters.show_category_id =
        filterFormFields.show_category_id !== undefined
          ? getSelectedDropdownValues(filterFormFields.show_category_id)
          : null;
    }

    if (showPendingFillsCheckbox) {
      selectedFilters.show_pending_fills = filterFormFields.show_pending_fills
        ? getSelectedDropdownValues(filterFormFields.show_pending_fills)
        : null;
    }
    
    if (showHighRiskListingsOnlyCheckbox) {
      selectedFilters.high_risk_listings_only = filterFormFields.high_risk_listings_only
        ? getSelectedDropdownValues(filterFormFields.high_risk_listings_only)
        : null;
    }

    if (showBroadcastedCheckbox) {
      selectedFilters.show_broadcasted_listings = filterFormFields.show_broadcasted_listings
        ? getSelectedDropdownValues(filterFormFields.show_broadcasted_listings)
        : null;
    }

    if (showLowAvailabilityListingsOnlyCheckbox) {
      selectedFilters.low_or_no_availability_listings_only = filterFormFields.low_or_no_availability_listings_only
        ? getSelectedDropdownValues(filterFormFields.low_or_no_availability_listings_only)
        : null;
    }

    if (showEventsPendingVerificationCheckbox) {
      selectedFilters.events_pending_verification = filterFormFields.events_pending_verification
        ? getSelectedDropdownValues(filterFormFields.events_pending_verification)
        : null;
    }

    if (showCustomerClassificationDropdown) {
      selectedFilters.sale_type = filterFormFields.sale_type
        ? getSelectedDropdownValues(filterFormFields.sale_type)
        : null;
    }

    if (addonFilters) {
      addonFilters.forEach(
        (eachAddon) =>
          (selectedFilters[eachAddon.filter_key] = eachAddon.filter_value)
      );
    }

    if (isResetAddonFilters && addonFilters) {
      addonFilters.forEach(
        (eachAddon) => (selectedFilters[eachAddon.filter_key] = null)
      );
      setAddonFilters && setAddonFilters(null);
    }

    setIsFilterDrawerActive(false);
    onApplyFilter(selectedFilters);
    setIsShowingRedDot(true);
    dispatch(toggleFilterDrawer(false));

    setDateRangeToDisplay(appliedDateRange);
    setCurrentAppliedFilters(selectedFilters);

    setIsFilterApplied(checkIsFilterApplied(selectedFilters));
  };

  const checkIsFilterApplied = (selectedFilters: any) => {
    let selectedKeys = Object.keys(selectedFilters).filter(
      (key) => selectedFilters[key] !== null
    );
    let defaultKeysAndValues = {};

    selectedKeys.forEach((key) => {
      if (key.includes("date")) {
        if (key.includes("_to")) {
          defaultKeysAndValues[key] =
            defaultFormValues[key.replace("_to", "")].defaultTo;
        } else if (key.includes("_from")) {
          defaultKeysAndValues[key] =
            defaultFormValues[key.replace("_from", "")].defaultFrom;
        }
      } else {
        defaultKeysAndValues[key] = defaultFormValues[key];
      }
    });

    for (let key of selectedKeys) {
      if (selectedFilters[key] !== defaultKeysAndValues[key]) return true;
    }

    return false;
  };

  const getAppliedFilterNames = (id: any) => {
    switch (id) {
      case "pl_date_range":
        return "P&L Date Range";
      case "po_date_range":
        return "PO Date Range";
      case "event_date_range":
        return "Event Date Range";
      case "invoice_date_range":
        return "Invoice Date Range";
      case "fill_date_range":
        return "Fill Date Range";
      case "custom_date_range":
        return "Date";
      case "delivery_date_range":
        return "Delivery Date Range";
      case "other_cost_date_range":
        return "Other Cost Date Range";
      case "broker_Id":
        return "Brokers";
      case "buyer_Id":
        return "Buyers";
      case "event_Id":
        return "Events";
      case "venue_Id":
        return "Venues";
      case "currency_Id":
        return "Currency";
      case "credit_card_Id":
        return "Cards";
      case "alert_Id":
        return "Alerts";
      case "sale_category":
        return "Sale Category";
      case "customer_classification":
        return "Customer Classification";
      case "no_ticketCost":
        return "No Ticket Cost";
      case "show_invoice_mapping_id":
        return "Invoice Mapping ID";
      case "state_Id":
        return "State";
      case "inventory_cancellation_type_Id":
          return "Inventory Type";
      case "broker_user_Id":
        return "Broker";
      case "lister_user_Id":
        return "Lister";
      case "buyer_user_Id":
        return "Buyer";
      case "shipper_user_Id":
        return "Shipper";
      case "seat_map_creator_user_id":
        return "Seat Map Creator";
      case "checker_user_id":
        return "Checker";
      case "seat_intelligence_Id":
        return "Seat Intelligence";
      case "scraping_status":
        return "Seat Intelligence";
      case "new_venue_Id":
        return "New Venues";
      case "seat_availability_update":
        return "Seat Availability Update";
      case "show_only_favorites":
        return "Show Only Favorites";
      case "high_priority_invoices":
        return "Show Only High Priority";
      case "include_my_broker_cards":
        return "Include my Broker Cards";
      case "show_pricing_issues":
        return "Pricing Issues";
      case "show_tb_placements":
        return "TB2.0 Placement";
      case "payment_status":
        return "Payment Status";
      case "aging_period":
        return "Aging Periods";
      case "fulfillment_status":
        return "Fulfillment Status";
      case "show_resolved_invoices":
        return "Show Resolved Invoices";
      case "show_category_id":
        return "Show Category";
      case "venue_scraping_type_id":
        return "Venue Scraping Type";
      case "favorite_venues_only":
        return "Favorite Venues Only";
      case "exclude_zero_valued_inventory":
        return "Exclude Zero Valued Inventory";
      case "exclude_sold_out_events":
        return "Exclude Sold Out Events";
      case "exclude_cancelled_events":
        return "Exclude Cancelled Events";
      case "show_scrape_failed_events":
        return "Show Unsuccessfully Scraped Events";
      case "show_pending_fills":
        return "Pending Fills";
      case "sale_type":
        return "Sale Type";
      case "low_or_no_availability_listings_only":
        return "Low Availability Listings";
      case "events_pending_verification":
        return "Events Pending Verification";
      case "high_risk_listings_only":
        return "High Risk Listings";
      case "show_broadcasted_listings":
        return "Show Only Broadcasted Event";
      case "show_missing_inventory_tag":
        return "Missing Inventory Tag";
    }
  };

  const getMappingValues = (invoice_mapping_id: any) => {
    if (invoice_mapping_id) {
      if (invoice_mapping_id === 0) {
        return "All";
      }
      return invoice_mapping_id === 1 ? "Yes" : "No";
    }
    return "0";
  };

  const getHighPriorityInvoiceMappingValue = (high_priority_invoices: boolean | undefined) => {
    if(!high_priority_invoices) {
      return "All";
    } else {
      return "Yes"
    }
  };

  const getIncludeMyBrokerCardsValue = (include_my_broker_cards: boolean | undefined) => {
    if(!include_my_broker_cards) {
      return "All";
    } else {
      return "Yes"
    }
  };

  const getAppliedFilterValues = (key: any, ids: any) => {
    ids = String(ids).split(",");

    switch (key) {
      case "sale_category":
        if (ids.length > 0 && saleCategoryList.length > 0)
          return saleCategoryList.filter((saleCategory) =>
            ids.includes(String(saleCategory.label))
          );
        break;
      case "customer_classification":
        if (ids.length > 0 && customerClassificationList.length > 0)
          return customerClassificationList.filter((customerClassification) =>
            ids.includes(String(customerClassification.label))
          );
        break;
      case "broker_Id":
        if (ids.length > 0 && brokerList.length > 0)
          return brokerList.filter((broker) =>
            ids.includes(String(broker.value))
          );
        break;
      case "payment_status":
        if (ids.length > 0 && paymentStatusList.length > 0)
          return paymentStatusList.filter((paymentStatus) =>
            ids.includes(String(paymentStatus.value))
          );
        break;
      case "aging_period":
        if (ids.length > 0 && agingPeriodList.length > 0)
          return agingPeriodList.filter((agingPeriod) =>
            ids.includes(String(agingPeriod.value))
          );
        break;
      case "fulfillment_status":
        if (ids.length > 0 && agingPeriodList.length > 0)
          return fulfillmentStatusList.filter((fulfillmentStatus) =>
            ids.includes(String(fulfillmentStatus.value))
          );
        break;
      case "buyer_Id":
        if (ids.length > 0 && buyerList.length > 0)
          return buyerList.filter((buyer) => ids.includes(String(buyer.value)));
        break;
      case "event_Id":
        if (ids.length > 0 && eventList.length > 0)
          return eventList.filter((event) => ids.includes(String(event.value)));
        break;
      case "venue_Id":
        if (ids.length > 0 && venueList.length > 0)
          return venueList.filter((venue) => ids.includes(String(venue.value)));
        break;
      case "currency_Id":
        if (ids.length > 0 && currencyList.length > 0) {
          return currencyList.filter((currency) =>
            ids.includes(String(currency.value))
          );
        }
        break;
      case "credit_card_Id":
        if (ids.length > 0 && cardList.length > 0)
          return cardList.filter((card) => ids.includes(String(card.value)));
        break;
      case "alert_Id":
        if (ids.length > 0 && alertList.length > 0)
          return alertList.filter((alert) => ids.includes(String(alert.value)));
        break;
      case "no_ticketCost":
        return filterFormFields.no_ticketCost
          ? filterFormFields.no_ticketCost
          : false;
      case "favorite_venues_only":
        return filterFormFields.favorite_venues_only
          ? filterFormFields.favorite_venues_only
          : false;
      case "exclude_zero_valued_inventory":
        return filterFormFields.exclude_zero_valued_inventory
          ? filterFormFields.exclude_zero_valued_inventory
          : false;
      case "exclude_sold_out_events":
        return filterFormFields.exclude_sold_out_events
          ? filterFormFields.exclude_sold_out_events
          : false;
      case "exclude_cancelled_events":
        return filterFormFields.exclude_cancelled_events
        ? filterFormFields.exclude_cancelled_events
        : false;
      case "show_scrape_failed_events":
        return filterFormFields.show_scrape_failed_events
        ? filterFormFields.show_scrape_failed_events
        : false;
      case "show_invoice_mapping_id":
        return getMappingValues(filterFormFields.show_invoice_mapping_id);
      case "high_priority_invoices":
        return getHighPriorityInvoiceMappingValue(filterFormFields.high_priority_invoices);
      case "include_my_broker_cards":
        return getIncludeMyBrokerCardsValue(filterFormFields.include_my_broker_cards);
      case "state_Id":
        if (ids.length > 0 && stateList.length > 0)
          return stateList.filter((state) => ids.includes(String(state.value)));
        break;
      case "inventory_cancellation_type_Id":
        if (ids.length > 0 && inventoryCancellationTypeList.length > 0)
          return inventoryCancellationTypeList.filter((inventory_cancellation) => ids.includes(String(inventory_cancellation.value)));
        break;
      case "broker_user_Id":
        if (ids.length > 0 && brokerUserList.length > 0)
          return brokerUserList.filter((broker) =>
            ids.includes(String(broker.value))
          );
        break;
      case "lister_user_Id":
        if (ids.length > 0 && listerUserList.length > 0)
          return listerUserList.filter((lister) =>
            ids.includes(String(lister.value))
          );
        break;
      case "buyer_user_Id":
        if (ids.length > 0 && buyerUserList.length > 0)
          return buyerUserList.filter((buyer) =>
            ids.includes(String(buyer.value))
          );
        break;
      case "shipper_user_Id":
        if (ids.length > 0 && shipperUserList.length > 0)
          return shipperUserList.filter((shipper) =>
            ids.includes(String(shipper.value))
          );
        break;
      case "seat_map_creator_user_id":
        if (ids.length > 0 && seatMapCreatorUserList.length > 0)
          return seatMapCreatorUserList.filter((seatMapCreator) =>
            ids.includes(String(seatMapCreator.value))
          );
        break;
      case "checker_user_id":
        if (ids.length > 0 && checkerUserList.length > 0)
          return checkerUserList.filter((checker) =>
            ids.includes(String(checker.value))
          );
        break;
      case "venue_scraping_type_id":
        if (ids.length > 0 && venueScrapingTypesList.length > 0)
          return venueScrapingTypesList.filter((eachVenueScrapingType) =>
            ids.includes(String(eachVenueScrapingType.value))
          );
        break;
      case "seat_intelligence_Id":
        if (ids.length > 0 && seatIntelligenceList.length > 0)
          return seatIntelligenceList.filter((status) =>
            ids.includes(String(status.value))
          );
        break;
      case "scraping_status":
        if (ids.length > 0 && scrapingStatusList.length > 0)
          return scrapingStatusList.filter((status) =>
            ids.includes(String(status.value))
          );
        break;
      case "new_venue_Id":
        if (ids.length > 0 && venueDatePresetList.length > 0)
          return venueDatePresetList.filter((venue) =>
            ids.includes(String(venue.value))
          );
        break;
      case "seat_availability_update":
        if (ids.length > 0 && seatAvailabilityUpdateOptions.length > 0)
          return seatAvailabilityUpdateOptions.filter(
            (seatAvailabilityUpdateOption) =>
              ids.includes(String(seatAvailabilityUpdateOption.value))
          );
        break;
      case "show_only_favorites":
        return filterFormFields.show_only_favorites
          ? filterFormFields.show_only_favorites
          : false;
      case "show_pricing_issues":
        return filterFormFields.show_pricing_issues
          ? filterFormFields.show_pricing_issues
          : false;
      case "show_tb_placements":
        return filterFormFields.show_tb_placements
          ? filterFormFields.show_tb_placements
          : false;
      case "show_pending_fills":
        return filterFormFields.show_pending_fills
          ? filterFormFields.show_pending_fills
          : false;
      case "high_risk_listings_only":
        return filterFormFields.high_risk_listings_only
          ? filterFormFields.high_risk_listings_only
          : false;
      case "show_broadcasted_listings":
        return filterFormFields.show_broadcasted_listings
          ? filterFormFields.show_broadcasted_listings
          : false;
      case "show_missing_inventory_tag":
        return filterFormFields.show_missing_inventory_tag
          ? filterFormFields.show_missing_inventory_tag
          : false;
      case "low_or_no_availability_listings_only":
        return filterFormFields.low_or_no_availability_listings_only
          ? filterFormFields.low_or_no_availability_listings_only
          : false;
      case "events_pending_verification":
        return filterFormFields.events_pending_verification
          ? filterFormFields.events_pending_verification
          : false;
      case "show_resolved_invoices":
        return filterFormFields.show_resolved_invoices
          ? filterFormFields.show_resolved_invoices
          : false;
      case "show_category_id":
        if (ids.length > 0 && showCategoryList.length > 0)
          return showCategoryList.filter((showCategory) =>
            ids.includes(String(showCategory.value))
          );
        break;
      case "sale_type":
        if (ids.length > 0 && saleTypeList.length > 0)
          return saleTypeList.filter((saleType) =>
            ids.includes(String(saleType.value))
          );
        break;
    }
  };

  const resetFilter = useCallback(() => {
    setFilterFormFields((prevState: any) => ({
      ...prevState,
      pl_date_from: defaultFormValues.pl_date.defaultFrom,
      pl_date_to: defaultFormValues.pl_date.defaultTo,
      invoice_date_from: defaultFormValues.invoice_date.defaultFrom,
      invoice_date_to: defaultFormValues.invoice_date.defaultTo,
      fill_date_from: defaultFormValues.fill_date.defaultFrom,
      fill_date_to: defaultFormValues.fill_date.defaultTo,
      po_date_from: defaultFormValues.po_date.defaultFrom,
      po_date_to: defaultFormValues.po_date.defaultTo,
      event_date_from: defaultFormValues.event_date.defaultFrom,
      event_date_to: defaultFormValues.event_date.defaultTo,
      custom_date_from: defaultFormValues.custom_date.defaultFrom,
      custom_date_to: defaultFormValues.custom_date.defaultTo,
      delivery_date_from: defaultFormValues.delivery_date.defaultFrom,
      delivery_date_to: defaultFormValues.delivery_date.defaultTo,
      other_cost_date_from: defaultFormValues.other_cost_date.defaultFrom,
      other_cost_date_to: defaultFormValues.other_cost_date.defaultTo,

      broker_Id: defaultFormValues.broker_Id,
      buyer_Id: defaultFormValues.buyer_Id,
      event_Id: defaultFormValues.event_Id,
      venue_Id: defaultFormValues.venue_Id,
      credit_card_Id: defaultFormValues.credit_card_Id,
      alert_Id: defaultFormValues.alert_Id,
      currency_Id: defaultFormValues.currency_Id,
      report_Id: defaultFormValues.report_Id,
      recipient_Id: defaultFormValues.recipient_Id,
      sale_category: defaultFormValues.sale_category,
      customer_classification: defaultFormValues.customer_classification,
      state_Id: defaultFormValues.state_Id,
      inventory_cancellation_type_Id: defaultFormValues.inventory_cancellation_type_Id,
      seat_intelligence_Id: defaultFormValues.seat_intelligence_Id,
      scraping_status: defaultFormValues.scraping_status,
      seat_availability_update: defaultFormValues.seat_availability_update,
      no_ticketCost: defaultFormValues.no_ticketCost,
      show_active_items_only: defaultFormValues.show_active_items_only,
      show_invoice_mapping_id: defaultFormValues.show_invoice_mapping_id,
      broker_user_Id: defaultFormValues.broker_user_Id,
      lister_user_Id: defaultFormValues.lister_user_Id,
      buyer_user_Id: defaultFormValues.buyer_user_Id,
      shipper_user_Id: defaultFormValues.shipper_user_Id,
      seat_map_creator_user_id: defaultFormValues.seat_map_creator_user_id,
      checker_user_id: defaultFormValues.checker_user_id,
      new_venue_Id: defaultFormValues.new_venue_Id,
      show_only_favorites: defaultFormValues.show_only_favorites,
      show_pricing_issues: defaultFormValues.show_pricing_issues,
      show_tb_placements: defaultFormValues.show_tb_placements,
      payment_status: defaultFormValues.payment_status,
      days_to_event: null,
      except_broker_user_ids: null,
      aging_period: defaultFormValues.aging_period,
      fulfillment_status: defaultFormValues.fulfillment_status,
      show_resolved_invoices: defaultFormValues.show_resolved_invoices,
      show_category_id: defaultFormValues.show_category_id,
      favorite_venues_only: defaultFormValues.favorite_venues_only,
      exclude_zero_valued_inventory: defaultFormValues.exclude_zero_valued_inventory,
      exclude_sold_out_events: defaultFormValues.exclude_sold_out_events,
      exclude_cancelled_events: defaultFormValues.exclude_cancelled_events,
      show_scrape_failed_events: defaultFormValues.show_scrape_failed_events,
      high_priority_invoices: defaultFormValues.high_priority_invoices,
      include_my_broker_cards: defaultFormValues.include_my_broker_cards,
      show_pending_fills: defaultFormValues.show_pending_fills,
      sale_type: defaultFormValues.sale_type,
      low_or_no_availability_listings_only: defaultFormValues.low_or_no_availability_listings_only,
      events_pending_verification: defaultFormValues.events_pending_verification,
      high_risk_listings_only: defaultFormValues.high_risk_listings_only,
      show_broadcasted_listings: true,
      venue_scraping_type_id: defaultFormValues.venue_scraping_type_id,
      show_missing_inventory_tag: defaultFormValues.show_missing_inventory_tag,
    }));

    setIsResetAddonFilters(true);

    setTimeout(() => {
      applyFilterRef?.current?.click();
    }, 100);
  },[filterFormFields, isResetAddonFilters]);

  useEffect(() => {
    if (!isDefaultAppliedFilter && isFilterDrawerReady) {
      setIsFilterDrawerActive(false);
      setIsDefaultAppliedFilter(true);
      dispatch(toggleFilterDrawer(false));
      setTimeout(() => {
        applyFilterRef?.current?.click();
      }, 400);
    }
  }, [isDefaultAppliedFilter, filterFormFields, isFilterDrawerReady]);

  useEffect(() => {
    if (!isAllMasterDataFetched) {
      setIsAllMasterDataFetched(true);
      if (
        (showPODatePicker ||
          showPLDatePicker ||
          showEventDatePicker ||
          showInvoiceDatePicker ||
          showFillDatePicker ||
          showCustomDatePicker ||
          showDeliveryDatePicker ||
          showOtherCostDatePicker) &&
        dates.length <= 0
      ) {
        dispatch(actions.getDateRangeFilterData());
      }

      if (showSaleCategoryDropdown && !saleCategoryList.length) {
        dispatch(actions.getSaleCategoryFilterData());
      }
      if (
        showCustomerClassificationDropdown &&
        !customerClassificationList.length
      ) {
        dispatch(actions.getCustomerClassificationFilterData());
      }
      if (showBrokerDropdown && !brokerList.length && current_report_id) {
        dispatch(actions.getBrokerFilterData(current_report_id));
      }
      if (showBuyerDropdown && !buyerList.length) {
        dispatch(actions.getBuyerFilterData());
      }
      if (showShipperUserDropdown && !shipperUserList?.length) {
        dispatch(actions.getShipperUserData());
      }
      if (showEventDropdown && !eventList.length) {
        dispatch(actions.getEventFilterData());
      }
      if (showVenueDropdown && !venueList.length) {
        dispatch(actions.getVenueFilterData());
      }
      if (showCurrencyDropdown && !currencyList.length) {
        dispatch(actions.getCurrencyFilterData());
      }
      if (showCardDropdown && !cardList.length) {
        dispatch(actions.getCardFilterData());
      }
      if (showAlertDropdown && !alertList.length) {
        dispatch(actions.getAlertFilterData());
      }
      if (showReportDropdown && !reportList.length && current_report_id) {
        dispatch(actions.getReportFilterData(current_report_id));
      }
      if (showRecipientDropdown && !recipientList.length) {
        dispatch(actions.getRecipientFilterData());
      }
      if (showPaymentStatusDropdown && paymentStatusList.length <= 0) {
        dispatch(actions.getPaymentStatusList());
      }
      if (showAgingPeriodDropdown && agingPeriodList.length <= 0) {
        dispatch(actions.getAgingPeriodList());
      }
      if (showFulfillmentStatusDropdown && fulfillmentStatusList.length <= 0) {
        dispatch(actions.getFulfillmentStatusList());
      }
      if (showVenueScrapingTypeDropdown && venueScrapingTypesList.length <= 0) {
        dispatch(actions.getVenueScrapingTypes());
      }
    }
  }, [
    isAllMasterDataFetched,
    showBrokerDropdown,
    showBuyerDropdown,
    showShipperUserDropdown,
    showEventDropdown,
    showVenueDropdown,
    showCurrencyDropdown,
    showCardDropdown,
    showAlertDropdown,
    showReportDropdown,
    showRecipientDropdown,
    showSaleCategoryDropdown,
    showCustomerClassificationDropdown,
    showSaleTypeDropdown,
    showVenueScrapingTypeDropdown,
    // showShowCategoryDropdown,
    dates,
    brokerList,
    buyerList,
    eventList,
    venueList,
    currencyList,
    cardList,
    alertList,
    reportList,
    recipientList,
    saleCategoryList,
    customerClassificationList,
    stateList,
    inventoryCancellationTypeList,
    seatIntelligenceList,
    scrapingStatusList,
    brokerUserList,
    buyerUserList,
    shipperUserList,
    venueDatePresetList,
    paymentStatusList,
    agingPeriodList,
    fulfillmentStatusList,
    // showCategoryList,
    venueScrapingTypesList,
    dispatch,
  ]);

  useEffect(() => {
    if (!isAllDatesGotAndSet && dates.length) {
      setIsAllDatesGotAndSet(true);
      setDefaultFormValues({
        ...defaultFormValues,
        po_date: {
          ...defaultFormValues.po_date,
          rangeFrom: getCustomDate(dates[0]?.po_date_from),
          rangeTo: getCustomDate(dates[0]?.po_date_to),
        },
        event_date: {
          ...defaultFormValues.event_date,
          rangeFrom: getCustomDate(dates[0]?.event_date_from),
          rangeTo: getCustomDate(dates[0]?.event_date_to),
        },
        pl_date: {
          ...defaultFormValues.pl_date,
          rangeFrom: getCustomDate(dates[0]?.pl_date_from),
          rangeTo: getCustomDate(dates[0]?.pl_date_to),
        },
        invoice_date: {
          ...defaultFormValues.invoice_date,
          rangeFrom: getCustomDate(dates[0]?.invoice_date_from),
          rangeTo: getCustomDate(dates[0]?.invoice_date_to),
        },
        fill_date: {
          ...defaultFormValues.fill_date,
          rangeFrom: getCustomDate(dates[0]?.fill_date_from),
          rangeTo: getCustomDate(dates[0]?.fill_date_to),
        },
        delivery_date: {
          ...defaultFormValues.delivery_date,
          rangeFrom: getCustomDate(dates[0]?.delivery_date_from),
          rangeTo: getCustomDate(dates[0]?.delivery_date_to),
        },
        other_cost_date: {
          ...defaultFormValues.other_cost_date,
          rangeFrom: getCustomDate(dates[0]?.other_cost_date_from),
          rangeTo: getCustomDate(dates[0]?.other_cost_date_to),
        },
        custom_date: {
          ...defaultFormValues.custom_date,
        },
      });
    }
  }, [dates, isAllDatesGotAndSet, defaultFormValues]);

  const handleCommonFilterChange = useCallback((
    key: string,
    selectedOptions: Array<any>
  ) => {
    const _filterFormFields = { ...filterFormFields };
    if (key === "broker_user_Id") {
      _filterFormFields.lister_user_Id = null;
      _filterFormFields.seat_map_creator_user_id = null;
      _filterFormFields.checker_user_id = null;
      _filterFormFields.buyer_user_Id = null;
    }

    let value: any = selectedOptions;
    if(Array.isArray(value) && value.length === 0){
      value = null;
    }
    
    setFilterFormFields({
      ..._filterFormFields,
      [key]: (value !== undefined && value !== '') ? value : null,
    });
  },[filterFormFields]);

  const setDateSelected = (
    key: string,
    startDate: dateType,
    endDate: dateType
  ) => {
    let fromField = key + "_from";
    let toField = key + "_to";
    setFilterFormFields({
      ...filterFormFields,
      [fromField]: startDate,
      [toField]: endDate,
    });
  };

  useEffect(() => {
    if (disabled === false && isDefaultAppliedFilter) {
      resetFilter();
      setIsShowingRedDot(false);
    }
  }, [disabled]);

  const filterDrawerCloseHandler = useCallback(() => {
    setIsFilterDrawerActive(false);
    dispatch(toggleFilterDrawer(false));
  },[setIsFilterDrawerActive, dispatch, toggleFilterDrawer]
  );

  return (
    <React.Fragment>
      {!disabled ? (
        <a
          onClick={(e) => {
            e.preventDefault();
            setIsFilterDrawerActive(true);
          }}
          className="filterCta"
        >
          <div className="pos-relative ">
            {isFilterApplied && isShowingRedDot && <div className="red-dot" />}
            <Tooltip title="Open Filter Drawer" placement="topRight">
              <i className="da icon-listview listviewIcon" />
            </Tooltip>
          </div>
        </a>
      ) : (
        <a className="filterCta disabledFilter">
          <div className="pos-relative ">
            <Tooltip title="Open Filter Drawer" placement="topRight">
              <i className="da icon-listview listviewIcon" />
            </Tooltip>
          </div>
        </a>
      )}
      <Drawer
        //width={600}
        className={`customDrawer dark`}
        placement="right"
        closable={false}
        onClose={filterDrawerCloseHandler}
        visible={
          (isFilterDrawerActive || isFilterDrawerVisibleRedux) &&
          isFilterDrawerReady
        }
      >
        <div className="drawerHeader">
          <h5>FILTER OPTIONS</h5>
          <div>
            <Tooltip title="Close Filter Drawer" placement="left">
              <Button
                onClick={filterDrawerCloseHandler}
              >
                <i className="da icon-arrow-right-lite"></i>
              </Button>
            </Tooltip>
          </div>
        </div>
        <h2>{filterName ? filterName : ""}</h2>

        {showCustomDatePicker && (
          <FilterOption
            keyValue={"custom_date"}
            type={"datePicker"}
            label={customDatePickerLabel ? customDatePickerLabel : "DATE"}
            from={filterFormFields.custom_date_from}
            to={filterFormFields.custom_date_to}
            defaultDateFormValue={defaultFormValues.custom_date}
            onchange={setDateSelected}
          />
        )}
        {showPODatePicker && (
          <FilterOption
            keyValue={"po_date"}
            type={"datePicker"}
            label={"PO DATE RANGE"}
            from={filterFormFields.po_date_from}
            to={filterFormFields.po_date_to}
            defaultDateFormValue={defaultFormValues.po_date}
            onchange={setDateSelected}
          />
        )}
        {showInvoiceDatePicker && (
          <FilterOption
            keyValue={"invoice_date"}
            type={"datePicker"}
            label={"INVOICE DATE RANGE"}
            from={filterFormFields.invoice_date_from}
            to={filterFormFields.invoice_date_to}
            defaultDateFormValue={defaultFormValues.invoice_date}
            onchange={setDateSelected}
          />
        )}
        {showEventDatePicker && (
          <FilterOption
            keyValue={"event_date"}
            type={"datePicker"}
            label={"EVENT DATE RANGE"}
            from={filterFormFields.event_date_from}
            to={filterFormFields.event_date_to}
            defaultDateFormValue={defaultFormValues.event_date}
            onchange={setDateSelected}
          />
        )}
        {showPLDatePicker && (
          <FilterOption
            keyValue={"pl_date"}
            type={"datePicker"}
            label={"P&L DATE RANGE"}
            from={filterFormFields.pl_date_from}
            to={filterFormFields.pl_date_to}
            defaultDateFormValue={defaultFormValues.pl_date}
            onchange={setDateSelected}
          />
        )}
        {showFillDatePicker && (
          <FilterOption
            keyValue={"fill_date"}
            type={"datePicker"}
            label={"FILL DATE RANGE"}
            from={filterFormFields.fill_date_from}
            to={filterFormFields.fill_date_to}
            defaultDateFormValue={defaultFormValues.fill_date}
            onchange={setDateSelected}
          />
        )}
        {showDeliveryDatePicker && (
          <FilterOption
            keyValue={"delivery_date"}
            type={"datePicker"}
            label={"DELIVERY DATE RANGE"}
            from={filterFormFields.delivery_date_from}
            to={filterFormFields.delivery_date_to}
            defaultDateFormValue={defaultFormValues.delivery_date}
            onchange={setDateSelected}
          />
        )}
        {showOtherCostDatePicker && (
          <FilterOption
            keyValue={"other_cost_date"}
            type={"datePicker"}
            label={"OTHER COST DATE RANGE"}
            from={filterFormFields.other_cost_date_from}
            to={filterFormFields.other_cost_date_to}
            defaultDateFormValue={defaultFormValues.other_cost_date}
            onchange={setDateSelected}
          />
        )}
        {showStateDropdown && (
          <FilterOption
            keyValue={"state_Id"}
            type={"dropdown"}
            label={"STATE"}
            dropdownMode={
              stateDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"State Name"}
            dropdownData={stateList}
            defaultValue={
              filterFormFields.state_Id !== null &&
              filterFormFields.state_Id !== undefined
                ? filterFormFields.state_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showPaymentStatusDropdown && (
          <FilterOption
            keyValue={"payment_status"}
            type={"dropdown"}
            label={"PAYMENT STATUS"}
            dropdownMode={
              paymentStatusDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Payment Status Type"}
            dropdownData={paymentStatusList}
            defaultValue={
              filterFormFields.payment_status !== null &&
              filterFormFields.payment_status !== undefined
                ? filterFormFields.payment_status
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showBrokerDropdown && (
          <FilterOption
            keyValue={"broker_Id"}
            type={"dropdown"}
            label={"BROKERS"}
            dropdownMode={
              brokerDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Broker"}
            dropdownData={brokerList}
            defaultValue={
              filterFormFields.broker_Id !== null &&
              filterFormFields.broker_Id !== undefined
                ? filterFormFields.broker_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showBrokerUserDropdown && (
          <FilterOption
            keyValue={"broker_user_Id"}
            type={"dropdown"}
            label={"BROKERS"}
            dropdownMode={
              brokerUserDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Broker Name"}
            dropdownData={brokerUserList}
            defaultValue={
              filterFormFields.broker_user_Id !== null &&
              filterFormFields.broker_user_Id !== undefined
                ? filterFormFields.broker_user_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showInventoryCancellationTypeDropdown && (
          <FilterOption
            keyValue={"inventory_cancellation_type_Id"}
            type={"dropdown"}
            label={"INVENTORY TYPE"}
            dropdownMode={
              inventoryCancellationTypeDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Inventory Type"}
            dropdownData={inventoryCancellationTypeList}
            defaultValue={
              filterFormFields.inventory_cancellation_type_Id !== null &&
              filterFormFields.inventory_cancellation_type_Id !== undefined
                ? filterFormFields.inventory_cancellation_type_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showListerDropdown && (
          <FilterOption
            keyValue={"lister_user_Id"}
            type={"dropdown"}
            label={"LISTERS"}
            dropdownMode={
              listerDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Lister Name"}
            dropdownData={
              filterFormFields.broker_user_Id
                ? listerUserList.filter(
                    (eachUser) =>
                      eachUser.broker_user_id ===
                      filterFormFields?.broker_user_Id
                  )
                : listerUserList
            }
            defaultValue={
              filterFormFields.lister_user_Id !== null &&
              filterFormFields.lister_user_Id !== undefined
                ? filterFormFields.lister_user_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showBuyerUserDropdown && (
          <FilterOption
            keyValue={"buyer_user_Id"}
            type={"dropdown"}
            label={"BUYERS"}
            dropdownMode={
              buyerUserDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Buyer Name"}
            dropdownData={
              filterFormFields.broker_user_Id
                ? buyerUserList.filter(
                    (eachUser) =>
                      eachUser.broker_user_id ===
                      filterFormFields?.broker_user_Id
                  )
                : buyerUserList
            }
            defaultValue={
              filterFormFields.buyer_user_Id !== null &&
              filterFormFields.buyer_user_Id !== undefined
                ? filterFormFields.buyer_user_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showShipperUserDropdown && (
          <FilterOption
            keyValue={"shipper_user_Id"}
            type={"dropdown"}
            label={"SHIPPERS"}
            dropdownMode={
              shipperUserDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Shipper Name"}
            dropdownData={shipperUserList}
            defaultValue={
              filterFormFields.shipper_user_Id !== null &&
              filterFormFields.shipper_user_Id !== undefined
                ? filterFormFields.shipper_user_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showSeatMapCreatorDropdown && (
          <FilterOption
            keyValue={"seat_map_creator_user_id"}
            type={"dropdown"}
            label={"SEAT MAP CREATORS"}
            dropdownMode={
              seatMapCreatorDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Seat Map Creator Name"}
            dropdownData={
              filterFormFields.broker_user_Id
                ? seatMapCreatorUserList.filter(
                    (eachUser) =>
                      eachUser.broker_user_id ===
                      filterFormFields?.broker_user_Id
                  )
                : seatMapCreatorUserList
            }
            defaultValue={
              filterFormFields.seat_map_creator_user_id !== null &&
              filterFormFields.seat_map_creator_user_id !== undefined
                ? filterFormFields.seat_map_creator_user_id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showCheckerDropdown && (
          <FilterOption
            keyValue={"checker_user_id"}
            type={"dropdown"}
            label={"CHECKERS"}
            dropdownMode={
              checkerDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Checker Name"}
            dropdownData={
              filterFormFields.broker_user_Id
                ? checkerUserList.filter(
                    (eachUser) =>
                      eachUser.broker_user_id ===
                      filterFormFields?.broker_user_Id
                  )
                : checkerUserList
            }
            defaultValue={
              filterFormFields.checker_user_id !== null &&
              filterFormFields.checker_user_id !== undefined
                ? filterFormFields.checker_user_id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showBuyerDropdown && (
          <FilterOption
            keyValue={"buyer_Id"}
            type={"dropdown"}
            label={"BUYERS"}
            dropdownMode={
              buyerDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Buyer"}
            dropdownData={buyerList}
            defaultValue={
              filterFormFields.buyer_Id ? filterFormFields.buyer_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showEventDropdown && (
          <FilterOption
            keyValue={"event_Id"}
            type={"dropdown"}
            label={"EVENTS"}
            dropdownMode={
              eventDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Event"}
            dropdownData={eventList}
            defaultValue={
              filterFormFields.event_Id ? filterFormFields.event_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showVenueDropdown && (
          <FilterOption
            keyValue={"venue_Id"}
            type={"dropdown"}
            label={"VENUES"}
            dropdownMode={
              venueDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Venues"}
            dropdownData={venueList}
            defaultValue={
              filterFormFields.venue_Id ? filterFormFields.venue_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showNewVenuesDropdown && (
          <FilterOption
            keyValue={"new_venue_Id"}
            type={"dropdown"}
            label={"NEW VENUES"}
            dropdownMode={
              newVenuesDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Recently Listed"}
            dropdownData={venueDatePresetList}
            defaultValue={
              filterFormFields.new_venue_Id ? filterFormFields.new_venue_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showCurrencyDropdown && (
          <FilterOption
            keyValue={"currency_Id"}
            type={"dropdown"}
            label={"CURRENCIES"}
            dropdownMode={
              currencyDropdownType === "single" ? undefined : "multiple"
            }
            dropdownData={currencyList}
            defaultValue={
              filterFormFields.currency_Id ? filterFormFields.currency_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showCardDropdown && (
          <FilterOption
            keyValue={"credit_card_Id"}
            type={"dropdown"}
            label={"CARDS"}
            dropdownMode={
              cardDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Cards"}
            dropdownData={cardList}
            defaultValue={
              filterFormFields.credit_card_Id
                ? filterFormFields.credit_card_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showAlertDropdown && (
          <FilterOption
            keyValue={"alert_Id"}
            type={"dropdown"}
            label={"ALERTS"}
            dropdownMode={
              alertDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Alerts"}
            dropdownData={alertList}
            defaultValue={
              filterFormFields.alert_Id ? filterFormFields.alert_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showReportDropdown && (
          <FilterOption
            keyValue={"report_Id"}
            type={"dropdown"}
            label={"REPORT"}
            dropdownMode={
              reportDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Report Name"}
            dropdownData={reportList}
            defaultValue={
              filterFormFields.report_Id ? filterFormFields.report_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showRecipientDropdown && (
          <FilterOption
            keyValue={"recipient_Id"}
            type={"dropdown"}
            label={"RECIPIENT"}
            dropdownMode={
              recipientDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Recipient Name"}
            dropdownData={recipientList}
            defaultValue={
              filterFormFields.recipient_Id ? filterFormFields.recipient_Id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showSeatAvailabilityUpdateDropdown && (
          <FilterOption
            keyValue={"seat_availability_update"}
            type={"dropdown"}
            label={"SEAT AVAILABILITY UPDATE"}
            dropdownMode={
              showSeatAvailabilityUpdateDropdownType === "single"
                ? undefined
                : "multiple"
            }
            placeholder={"Select Seat Availability Update Option"}
            dropdownData={seatAvailabilityUpdateOptions}
            defaultValue={
              filterFormFields.seat_availability_update
                ? filterFormFields.seat_availability_update
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showSeatIntelligenceDropdown && (
          <FilterOption
            keyValue={"seat_intelligence_Id"}
            type={"dropdown"}
            label={"SEAT INTELLIGENCE"}
            dropdownMode={
              seatIntelligenceDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Scraping Status"}
            dropdownData={seatIntelligenceList}
            defaultValue={
              filterFormFields.seat_intelligence_Id
                ? filterFormFields.seat_intelligence_Id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showScrapingStatusDropdown && (
          <FilterOption
            keyValue={"scraping_status"}
            type={"dropdown"}
            label={"SEAT INTELLIGENCE"}
            dropdownMode={
              scrapingStatusDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Scraping Status"}
            dropdownData={scrapingStatusList}
            defaultValue={
              filterFormFields.scraping_status
                ? filterFormFields.scraping_status
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showSaleCategoryDropdown && (
          <FilterOption
            keyValue={"sale_category"}
            type={"dropdown"}
            label={"SALE CATEGORY"}
            dropdownMode={
              saleCategoryDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Sale category"}
            dropdownData={saleCategoryList}
            defaultValue={
              filterFormFields.sale_category
                ? filterFormFields.sale_category
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showCustomerClassificationDropdown && (
          <FilterOption
            keyValue={"customer_classification"}
            type={"dropdown"}
            label={"CUSTOMER CLASSIFICATION"}
            dropdownMode={
              customerClassificationDropdownType === "single"
                ? undefined
                : "multiple"
            }
            placeholder={"Customer Classification"}
            dropdownData={customerClassificationList}
            defaultValue={
              filterFormFields.customer_classification
                ? filterFormFields.customer_classification
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showAgingPeriodDropdown && (
          <FilterOption
            keyValue={"aging_period"}
            type={"dropdown"}
            label={"AGING PERIODS"}
            dropdownMode={
              agingPeriodDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select Aging Period Types"}
            dropdownData={agingPeriodList}
            defaultValue={
              filterFormFields.aging_period !== null &&
              filterFormFields.aging_period !== undefined
                ? filterFormFields.aging_period
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showFulfillmentStatusDropdown && (
          <FilterOption
            keyValue={"fulfillment_status"}
            type={"dropdown"}
            label={"FULFILLMENT STATUS"}
            dropdownMode={
              fulfillmentStatusDropdownType === "single"
                ? undefined
                : "multiple"
            }
            placeholder={"Select a Fulfillment Status Type"}
            dropdownData={fulfillmentStatusList}
            defaultValue={
              filterFormFields.fulfillment_status !== null &&
              filterFormFields.fulfillment_status !== undefined
                ? filterFormFields.fulfillment_status
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showShowCategoryDropdown && (
          <FilterOption
            keyValue={"show_category_id"}
            type={"dropdown"}
            label={"SHOW CATEGORY"}
            dropdownMode={
              showCategoryDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Select a Show Category Type"}
            dropdownData={showCategoryList}
            defaultValue={
              filterFormFields.show_category_id
                ? filterFormFields.show_category_id
                : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showSaleTypeDropdown && (
          <FilterOption
            keyValue={"sale_type"}
            type={"dropdown"}
            label={"SALE TYPE"}
            dropdownMode={
              saleTypeDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Sale Type"}
            dropdownData={saleTypeList}
            defaultValue={
              filterFormFields.sale_type ? filterFormFields.sale_type : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showVenueScrapingTypeDropdown && (
          <FilterOption
            keyValue={"venue_scraping_type_id"}
            type={"dropdown"}
            label={"VENUE SCRAPING TYPE"}
            info={"Should not be used with Seat Intelligence"}
            dropdownMode={
              venueScrapingTypeDropdownType === "single" ? undefined : "multiple"
            }
            placeholder={"Venue Scraping Type"}
            dropdownData={venueScrapingTypesList}
            defaultValue={
              filterFormFields.venue_scraping_type_id ? filterFormFields.venue_scraping_type_id : []
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showNoTicketCheckbox && (
          <FilterOption
            keyValue={"no_ticketCost"}
            type={"checkbox"}
            label={"No Ticket Cost"}
            defaultValue={filterFormFields.no_ticketCost}
            onchange={handleCommonFilterChange}
          />
        )}
        {
          showExcludeZeroValuedInventory && (
            <FilterOption
              keyValue={"exclude_zero_valued_inventory"}
              type={"checkbox"}
              label={"Exclude Zero Valued Inventory"}
              defaultValue={filterFormFields.exclude_zero_valued_inventory}
              onchange={handleCommonFilterChange}
            />
          )
        }
        {showFavoriteVenuesOnlyCheckbox && (
          <FilterOption
            keyValue={"favorite_venues_only"}
            type={"checkbox"}
            label={"Favorite Venues Only"}
            defaultValue={filterFormFields.favorite_venues_only}
            onchange={handleCommonFilterChange}
          />
        )}
        {showSoldOutEventsCheckbox && (
          <FilterOption
            keyValue={"exclude_sold_out_events"}
            type={"checkbox"}
            label={"Exclude Sold Out Events"}
            defaultValue={filterFormFields.exclude_sold_out_events}
            onchange={handleCommonFilterChange}
          />
        )}
        {showCancelledEventsCheckbox && (
          <FilterOption
            keyValue={"exclude_cancelled_events"}
            type={"checkbox"}
            label={"Exclude Cancelled Events"}
            defaultValue={filterFormFields.exclude_cancelled_events}
            onchange={handleCommonFilterChange}
          />
        )}
        {showFailedScrapedEventsCheckbox && (
          <FilterOption
            keyValue={"show_scrape_failed_events"}
            type={"checkbox"}
            label={"Show Unsuccessfully Scraped Events"}
            defaultValue={filterFormFields.show_scrape_failed_events}
            onchange={handleCommonFilterChange}
          />
        )}
        {showHighPriorityInvoicesCheckbox && (
          <FilterOption
            keyValue={"high_priority_invoices"}
            type={"checkbox"}
            label={"High Priority Only"}
            defaultValue={filterFormFields.high_priority_invoices}
            onchange={handleCommonFilterChange}
          />
        )}
        {showIncludeMyBrokerCardsCheckbox && (
          <FilterOption
            keyValue={"include_my_broker_cards"}
            type={"checkbox"}
            label={"Include my Broker Cards"}
            defaultValue={filterFormFields.include_my_broker_cards}
            onchange={handleCommonFilterChange}
          />
        )}
        {showActiveItemsOnlyCheckbox && (
          <FilterOption
            keyValue={"show_active_items_only"}
            type={"checkbox"}
            label={"Show Active Items Only"}
            defaultValue={filterFormFields.show_active_items_only}
            onchange={handleCommonFilterChange}
          />
        )}
        {showOnlyFavoritesCheckbox && (
          <FilterOption
            keyValue={"show_only_favorites"}
            type={"checkbox"}
            label={"Show Only Favorites"}
            defaultValue={filterFormFields.show_only_favorites}
            onchange={handleCommonFilterChange}
          />
        )}
        {showInvoiceMappingId && (
          <FilterOption
            keyValue={"show_invoice_mapping_id"}
            type={"dropdown"}
            label={"INVOICE MAPPING ID"}
            dropdownData={[
              { label: "All", value: "0" },
              { label: "Yes", value: "1" },
              { label: "No", value: "2" },
            ]}
            defaultValue={
              filterFormFields.show_invoice_mapping_id
                ? filterFormFields.show_invoice_mapping_id
                : "0"
            }
            onchange={handleCommonFilterChange}
          />
        )}
        {showPricingIssuesCheckbox && (
          <FilterOption
            keyValue={"show_pricing_issues"}
            type={"checkbox"}
            label={"Pricing Issues"}
            defaultValue={filterFormFields.show_pricing_issues}
            onchange={handleCommonFilterChange}
          />
        )}
        {showTbPlacementCheckbox && (
          <FilterOption
            keyValue={"show_tb_placements"}
            type={"checkbox"}
            label={"TB2.0 Placement"}
            defaultValue={filterFormFields.show_tb_placements}
            onchange={handleCommonFilterChange}
          />
        )}
        {showPendingFillsCheckbox && (
          <FilterOption
            keyValue={"show_pending_fills"}
            type={"checkbox"}
            label={"Pending Fills"}
            defaultValue={filterFormFields.show_pending_fills}
            onchange={handleCommonFilterChange}
          />
        )}
        {showLowAvailabilityListingsOnlyCheckbox && (
          <FilterOption
            keyValue={"low_or_no_availability_listings_only"}
            type={"checkbox"}
            label={"Low Availability Listings Only"}
            defaultValue={filterFormFields.low_or_no_availability_listings_only}
            onchange={handleCommonFilterChange}
          />
        )}
        {showEventsPendingVerificationCheckbox && (
          <FilterOption
            keyValue={"events_pending_verification"}
            type={"checkbox"}
            label={"Show Events Pending Verification"}
            defaultValue={filterFormFields.events_pending_verification}
            onchange={handleCommonFilterChange}
          />
        )}
        {showHighRiskListingsOnlyCheckbox && (
          <FilterOption
            keyValue={"high_risk_listings_only"}
            type={"checkbox"}
            label={"High Risk Listings Only"}
            defaultValue={filterFormFields.high_risk_listings_only}
            onchange={handleCommonFilterChange}
          />
        )}
        {showBroadcastedCheckbox && (
          <FilterOption
            keyValue={"show_broadcasted_listings"}
            type={"checkbox"}
            label={"Show Only Broadcasted Event"}
            defaultValue={filterFormFields.show_broadcasted_listings}
            onchange={handleCommonFilterChange}
          />
        )}
        {showResolvedInvoicesCheckbox && (
          <FilterOption
            keyValue={"show_resolved_invoices"}
            type={"checkbox"}
            label={"SHOW RESOLVED INVOICES"}
            defaultValue={filterFormFields.show_resolved_invoices}
            onchange={handleCommonFilterChange}
          />
        )}
        {showMissingInventoryTagCheckbox && (
          <FilterOption
            keyValue={"show_missing_inventory_tag"}
            type={"checkbox"}
            label={"Missing Inventory Tag"}
            defaultValue={filterFormFields.show_missing_inventory_tag}
            onchange={handleCommonFilterChange}
          />
        )}
        <div className="formEnd">
          <div className="btnsRight">
            <Button className="linkBtn" onClick={resetFilter}>
              RESET
            </Button>
            <input
              className="btnOk"
              value="APPLY"
              ref={applyFilterRef}
              onClick={doApplyFilter}
              type="button"
            />
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default React.memo(FilterDrawer);
